/* Body */
body,
p,
li {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: 0.01em;
  color: #000000;
}

a {
  text-decoration: none !important;
}

h1 {
  padding-top: 1rem;
}

h2 {
  color: black;
}

/* Nav */
.nav-size {
  font-size: 25px;
}

.nav-style {
  color: black;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
}

.nav-style:hover {
  color: #4a00d8;
  text-decoration: none;
}

/* Home */
.home-title {
  padding-top: 8rem;
  margin-bottom: 2rem;
}

.home-subtitle {
  color: grey;
}

.home-project {
  color: black;
  padding-top: 3rem;
}

.home-project:hover {
  color: #4a00d8;
  text-decoration: none;
}

.home-project-subtitle {
  padding-top: 1rem;
}

/* About */
.profile {
  width: auto;
  height: 400px;
  display: block;
  margin: 2rem auto;
}

.about-title {
  text-align: center;
  padding-top: 4rem;
}

.about-description {
  box-sizing: border-box;
  margin: 0px auto;
  padding-top: 4rem;
  max-width: 740px;
  margin-bottom: 6rem;
}

.no-dot {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Blog */

.table-of-contents {
  padding-top: 8rem;
  margin-bottom: 10rem;
}

.blog-title {
  color: black;
}

.blog-title:hover {
  color: #4a00d8;
  text-decoration: none;
}

.blog-article {
  box-sizing: border-box;
  margin: 0px auto;
  padding-top: 4rem;
  max-width: 740px;
  margin-bottom: 6rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Footer */
.icon {
  font-size: 40px;
  padding: 1rem 1rem;
}

.padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* animated orange emoji*/
span.wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

pre {
  display: block;
  text-align: left;
  white-space: pre-line;
  position: relative;
  word-break: normal;
  word-wrap: normal;
  line-height: 2;
  background-color: #f7f7f7;
  padding: 15px;
  margin: 10px;
  border-radius: 5px;
  color: black;
}